import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/DashboardView.vue')
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('../views/BusinessView.vue')
  },
  {
    path: '/native-login/:userEmail/:deviceToken/:mode',
    name: 'native-login',
    component: () => import('../views/NativeLoginView.vue')
  },
  {
    path: '/wait-for-payment',
    name: 'wait-for-payment',
    component: () => import('../views/WaitForPaymentView.vue')
  },
  {
    path: '/native-app-premium-enabled',
    name: 'wait-for-redirect',
    component: () => import('../views/WaitForRedirectView.vue')
  },
  {
    path: '/native-app-open-receipt/:url',
    name: 'open-receipt',
    component: () => import('../views/OpenReceiptView.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
