import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container home-wrapper" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 1,
  class: "sign-in-form"
}
const _hoisted_4 = ["disabled"]

import { onMounted, ref, watch, inject } from 'vue';
import { useRouter } from 'vue-router'

import CydAPIClient from '../cyd-api-client';

import validator from 'validator';
import UAParser from 'ua-parser-js';

type SignInState = 'start' | 'registerDevice' | 'token';

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const router = useRouter();

const fetchUserPremium = inject('fetchUserPremium') as () => Promise<void>;

const getAPIClient = inject('getAPIClient') as () => CydAPIClient;
const apiClient = getAPIClient();

const checkIfLoggedIn = inject('checkIfLoggedIn') as () => Promise<boolean>;

const signInState = ref<SignInState>('start');

const email = ref<string>("");
const continueButtonEnabled = ref(false);
const verificationCode = ref<string>("");
const verificationCodeDisabled = ref(false);
const verificationCodeInputEl = ref<HTMLInputElement | null>(null);

const parser = new UAParser();
const ua = parser.getResult();
const deviceDescription = `${ua.os.name}: ${ua.browser.name} Browser`;

watch(email, (newEmail) => {
  if (newEmail && typeof newEmail === 'string' && validator.isEmail(newEmail)) {
    continueButtonEnabled.value = newEmail.trim() !== '';
  }
});

const authenticate = async () => {
  if (!email.value || !validator.isEmail(email.value)) {
    return;
  }

  const resp = await apiClient.authenticate({
    email: email.value
  });
  if (typeof resp !== 'boolean' && resp.error) {
    if (resp.status == 403) {
      alert('At the moment, sign-ins are restricted to specific people. Sorry!');
      email.value = '';
      return;
    }

    alert('Failed to sign in. Check your email address and try again.');
    return;
  }

  apiClient.setUserEmail(email.value);

  signInState.value = 'registerDevice';
};

const verificationCodeFixInput = (event: Event) => {
  const inputEvent = event as InputEvent;
  const target = inputEvent.target as HTMLInputElement;
  verificationCode.value = target.value.replace(/\D/g, '').slice(0, 6);
  if (verificationCode.value.length >= 6) {
    verificationCodeDisabled.value = true;
    setTimeout(registerDevice, 100);
  }
};

const registerDevice = async () => {
  // Register the device
  const registerDeviceResp = await apiClient.registerDevice({
    email: email.value,
    verification_code: verificationCode.value,
    description: deviceDescription,
    device_type: 'browser'
  });
  if ("error" in registerDeviceResp) {
    verificationCode.value = '';
    verificationCodeInputEl.value?.focus();
    alert('Invalid verification code.');
    verificationCodeDisabled.value = false;
    return;
  }
  if (!registerDeviceResp.device_token) {
    alert('Failed to register device. Please try again later.');
    await goBack();
    return;
  }

  // Save to localStorage
  localStorage.setItem('userEmail', email.value);
  localStorage.setItem('deviceUUID', registerDeviceResp.uuid);
  localStorage.setItem('deviceToken', registerDeviceResp.device_token);

  // Get a new API token
  apiClient.setDeviceToken(registerDeviceResp.device_token);
  const pingResp = await apiClient.ping();
  if (!pingResp) {
    alert('Failed to register new device. Please try again later.');
    await goBack();
    return;
  }

  // If login was successful, this should redirect to the dashboard
  if (await checkIfLoggedIn()) {
    await fetchUserPremium();
    router.push('/dashboard');
  }
};

async function goBack() {
  verificationCode.value = '';
  signInState.value = 'start';
}

onMounted(async () => {
  email.value = localStorage.getItem('userEmail') || '';
  if (!await checkIfLoggedIn()) {
    router.push('/');
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "teaser" }, [
      _createElementVNode("p", { class: "fw-bold" }, "It's time to regain control of your data.")
    ], -1)),
    _cache[5] || (_cache[5] = _createElementVNode("hr", null, null, -1)),
    (signInState.value == 'start')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-center" }, "Sign in to Cyd using your email address.", -1)),
          _createElementVNode("form", {
            onSubmit: _withModifiers(authenticate, ["prevent"]),
            class: "sign-in-form d-flex"
          }, [
            _withDirectives(_createElementVNode("input", {
              type: "email",
              class: "form-control me-2",
              placeholder: "Email address",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
              required: ""
            }, null, 512), [
              [_vModelText, email.value]
            ]),
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn-primary",
              disabled: !continueButtonEnabled.value
            }, "Continue", 8, _hoisted_2)
          ], 32)
        ], 64))
      : (signInState.value == 'registerDevice')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-center" }, "We've emailed you a verification code. Enter it below.", -1)),
            _withDirectives(_createElementVNode("input", {
              rel: "verificationCodeInputEl",
              type: "text",
              class: "form-control me-2",
              placeholder: "123456",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((verificationCode).value = $event)),
              disabled: verificationCodeDisabled.value,
              onInput: verificationCodeFixInput,
              required: ""
            }, null, 40, _hoisted_4), [
              [_vModelText, verificationCode.value]
            ]),
            _createElementVNode("div", { class: "text-right mt-2" }, [
              _createElementVNode("button", {
                class: "btn btn-sm btn-secondary",
                onClick: goBack
              }, "Back")
            ])
          ]))
        : _createCommentVNode("", true)
  ]))
}
}

})